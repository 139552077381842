import React from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PageContent from '../components/PageContent';
import InventoryView from '../components/InventoryView';
import SelectedItems from '../components/SelectedItems';
import Heading from '../components/Heading';
import ListingReceiptModal from '../components/ListingReceiptModal';

import api from '../api/api';

class Sell extends React.Component {

	selectItemsCap = 10;

	state = {
		inventory: false,
		selectedItems: {},
		responseInventory: false,
		listReceipt: false
	}

	componentDidMount () {
		api.getInventory((success, data) => {
      let state = { responseInventory: true }
      if (data) state.inventory = data;
      this.setState(state);
		});
	}

	selectItem = (assetid) => {

		if (this.state.selectedItems[assetid] !== undefined) {
			this.unselectItem(assetid);
			return false;
		}

		if ((Object.keys(this.state.selectedItems).length + 1) > this.selectItemsCap) {
			return false;
		}

		this.setState(prevState => ({
			selectedItems: {
				...prevState.selectedItems,
				[assetid]: {
					item: assetid,
					price: false
				}
			}
		}));
	}

	priceChange = (event, assetid) => {
		let new_price = event.target.value;
		this.setState(prevState => ({
			selectedItems: {
				...prevState.selectedItems,
				[assetid]: {
					assetid: assetid,
					price: new_price
				}
			}
		}));
	}

	unselectItem = (assetid) => {
		var selectedItems = { ...this.state.selectedItems }
		delete selectedItems[assetid];
		this.setState({ selectedItems: selectedItems});
	}

	postListItems = () => {
		api.listItems(this.state.selectedItems, (success, data) => {
			if (success === true && data.status === true) {
				this.setState({ listReceipt: data.listing_status, selectedItems: {} });
			}
		});
	}

	clearListReceipt = () => {
		this.setState({ listReceipt: false });
	}

	render () {
		return (
			<>
				<ListingReceiptModal inventory={ this.state.inventory } clearListReceipt={ this.clearListReceipt } listReceipt={ this.state.listReceipt } />
				<Navbar />
				<PageContent className="sell" AutoExpand={ true }>
					<Heading>Sälj CSGO skins</Heading>
					<p>Löksås ipsum mjuka groda omfångsrik samtidigt sorgliga flera därmed, jäst groda sitt sig ingalunda har är se icke, från har bland inom dunge brunsås i. Hela denna från jäst rännil tiden sin, där ordningens sitt från dimma. Tre kunde redan stora tiden miljoner vidsträckt, miljoner därmed händer äng sitt, mot stig om stora del. Dimmhöljd del träutensilierna sax kan denna för kan sin, hela av vid samma hav äng denna, helt tre hav hans både miljoner varit.</p>
					<div className="layout">

						<div>
							<InventoryView
								inventory={ this.state.inventory }
								itemClick={ this.selectItem }
								selectedItems={ this.state.selectedItems }
							/>
						</div>

						<div>
							<SelectedItems
								priceChange={ this.priceChange }
								inventory={ this.state.inventory }
								unselectItem={ this.unselectItem }
								selectedItems={ this.state.selectedItems }
								postListItems={ this.postListItems }
							/>
						</div>

					</div>
				</PageContent>
				<Footer />
			</>
		)
	}
}

export default Sell;