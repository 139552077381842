import React from 'react';
import siteConfig from '../contexts/siteConfig';

function SteamLoginButton (props) {

	const context = React.useContext(siteConfig);

	return (
		<a className="steam-login-button" href={ context.siteConfig.login_url || '#' }>{ props.children }</a>
	)
}

export default SteamLoginButton;