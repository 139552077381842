import React from "react";
import PageContent from '../components/PageContent';
import SteamLoginButton from '../components/SteamLoginButton';
import userContext from '../contexts/userContext';

function Hero () {

	const context = React.useContext(userContext);

	return (
		<div className="hero">
			<img className="background-image" src="/images/hero.png" alt="Hero" />
			<PageContent className="hero-content">
				<div className="wrapper">
					<div className="title">Snabbt. Säkert. Enkelt</div>
					<p className="tagline">Den bästa marknadsplatsen för att köpa och sälja CS:GO Skins. Automatisk Swish. Ingen trade-lock. Peer to peer.</p>
					{ context.user === false ? (<SteamLoginButton>Registrera nu</SteamLoginButton>) : null }
				</div>
			</PageContent>
		</div>
	)
}

export default Hero;