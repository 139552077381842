var api = {
	api_url: 'https://api.skinbutiken.com/api'
}

api.siteConfig = function (callback) {
	fetch(api.api_url + '/site_config', { credentials: 'include' })
	.then(response => response.json())
	.then(data => {
		callback(true, data.data)
	})
	.catch(error => {
		callback(false, false)
	});
}

api.userStatus = function (callback) {
	fetch(api.api_url + '/status', { credentials: 'include' })
	.then(response => response.json())
	.then(data => {
		callback(true, data.data)
	})
	.catch(error => {
		callback(false, false)
	});
}

api.getInventory = function (callback) {
	fetch(api.api_url + '/inventory', { credentials: 'include' })
	.then(response => response.json())
	.then(data => {
		callback(true, data.inventory)
	})
	.catch(error => {
		callback(false, false)
	});
}

api.listItems = function (data, callback) {
	let formData = new FormData();

	formData.append('list_items', JSON.stringify(data));

	fetch(api.api_url + '/list_items', {
		method: 'post',
		credentials: 'include',
		body: formData
	}).then(response => {
		return response.json();
	}).then(data => {
		callback(true, data)
	}).catch(error => {
		callback(false, false)
	});
}

api.getListedItems = function (callback) {
	fetch(api.api_url + '/items', { credentials: 'include' })
	.then(response => response.json())
	.then(data => {
		callback(true, data.items)
	})
	.catch(error => {
		callback(false, false)
	});
}

api.getItemInfo = function (assetid, callback) {
	fetch(api.api_url + '/item_info/' + assetid, { credentials: 'include' })
	.then(response => response.json())
	.then(data => {
		callback(true, data.item)
	})
	.catch(error => {
		callback(false, false)
	});
}

export default api;