import React from "react";
import Modal from './Modal';
import Btn from '../components/Btn';

function ListingReceiptModal (props) {

	if (props.inventory === false || props.listReceipt === false) return null;

	const renderItems = () => {
		let data = [];

		Object.keys(props.listReceipt).forEach(assetid => {
			let list_data = props.listReceipt[assetid];
			let item = props.inventory[assetid];

			if (item === false || item === undefined) return false;

			data.push(
				<div className="listing-item" key={ assetid }>
					<img className="icon" src={ 'https://steamcommunity-a.akamaihd.net/economy/image/' + item.description.icon_url } alt="Steam item"  />
					<div className="item-details">
						<div className="name">{ item.description.name }</div>
						<div className="exterior">{ item.summary.exterior }</div>
					</div>
					<div className="status">
						<div className="title">{ list_data.status === true ? 'Lyckades' : 'Misslyckades' }</div>
						{ list_data.error_msg !== false ? (<div className="error-message">{ list_data.error_msg }</div>) : '' }
					</div>
				</div>
			)
			
		});

		return data;

	}

	return (
		<Modal className="listing-attempts-modal" show={ true } closeModal={ props.clearListReceipt }>
			<h2>Status</h2>
			<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
			<div className="listing-attempts">
				{ renderItems() }
			</div>
			<div className="btn-wrapper">
				<Btn onClick={ props.clearListReceipt }>Uppfattat!</Btn>
			</div>
		</Modal>
	)
}


export default ListingReceiptModal;