import React from 'react';
import Loader from './Loader';
import api from '../api/api';
import Item from '../components/Item';
import ItemModal from '../components/ItemModal';

class InventoryView extends React.Component {

	state = {
		items: {},
		loading: true,
		showItemModal: false
	}

	componentDidMount () {
		api.getListedItems((status, data) => {
			let state = { loading: false }
      if (data) state.items = data;
      this.setState(state);
		});
	}

	itemClick = (assetid) => {
		this.setState({ showItemModal: assetid });
	}

	closeModal = () => {
		this.setState({ showItemModal: false });
	}

	renderItems = () => {
		let output = [];

		Object.keys(this.state.items).forEach((assetid) => {
			let item = this.state.items[assetid];
			output.push(<Item item={ item } key={ assetid } itemClick={ this.itemClick }/>)
		});

		return output;
	}

	render () {

		if (this.state.loading === true) return <Loader />

		return (
			<>
				<ItemModal showItemModal={ this.state.showItemModal } closeModal={ this.closeModal } items={ this.state.items } />
				<div className="listed-items">
					{ this.renderItems() }
				</div>
			</>
		)
	}
}

export default InventoryView