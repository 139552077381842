import React from 'react';
import Loader from './Loader';
import Item from '../components/Item';

class InventoryView extends React.Component {

	state = {
		itemModalOpen: false
	}

	closeModal = () => {
		this.setState({ itemModalOpen: false });
	}

	openModal = () => {
		this.setState({ itemModalOpen: true });
	}

	itemClick = (index) => {
		if (this.props.itemClick) {
			this.props.itemClick(index);
		}
	}

	alreadySelected = (assetid) => {
		if (Object.keys(this.props.selectedItems).length && this.props.selectedItems[assetid]) return true;
		return false;
	}

	renderItems = () => {
		let output = [];

		Object.keys(this.props.inventory).forEach((assetid) => {
			let item = this.props.inventory[assetid];
			output.push(<Item item={ item } selected={ this.alreadySelected(assetid) } key={ assetid } itemClick={ this.itemClick }/>)
		});

		return output;
	}

	render () {

		if (this.props.inventory === false) return <Loader />

		return (
			<div className="inventory">
				{ this.renderItems() }
			</div>
		)
	}
}

export default InventoryView