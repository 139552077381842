import React from 'react';

function Item (props) {

	const item = props.item;
	const assetid = item.asset.assetid;
	var selected = false;
	var itemClick = function () { return false; }

	if (props.selected === true) {
		selected = true;
	}

	if (props.itemClick) {
		itemClick = props.itemClick;
	}

	return (
		<div className={ "item" + (selected === true ? ' selected' : '') } onClick={ (e) => { itemClick(assetid) } }>
			<img className="icon" src={ 'https://steamcommunity-a.akamaihd.net/economy/image/' + item.description.icon_url } alt="Steam item" />
			<div className="name">{ item.description.name }</div>
			<div className="exterior">{ item.summary.exterior }</div>
			{ item.csgo_float.floatvalue ? (<div className="float">{ item.csgo_float.floatvalue.toFixed(4) }</div>) : null }
			{ item.price ? (<div className="price">{ item.price } Kr</div>) : null }
		</div>
	)
}

export default Item;