import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import 'normalize.css';
import './styles/main.scss';

import StartPage from './pages/StartPage';
import About from './pages/About';
import NotFound from './pages/NotFound';
import Support from './pages/Support';
import Sell from './pages/Sell';
import Loading from './pages/Loading';
import Account from './pages/Account';
import Checkout from './pages/Checkout';

import api from './api/api';
import userContext from './contexts/userContext';
import siteConfig from './contexts/siteConfig';
import notificationContext from './contexts/notificationContext';
import Notification from './components/Notification';
import RequireAuth from './components/RequireAuth';

class App extends React.Component {

  state = {
    loading: false,
    user: false,
    siteConfig: false,
    responseUserStatus: false,
    responseSiteConfig: false,
    notification: false
  }

  componentDidMount () {
    this.refreshUser();
    this.refreshSiteConfig();
  }

  refreshUser = () => {
    api.userStatus((success, data) => {
      if (success === false) return false;
      let state = { user: false, responseUserStatus: true }
      if (data) state.user = data;
      this.setState(state);
    });
  }

  refreshSiteConfig = () => {
    api.siteConfig((success, data) => {
      if (success === false) return false;
      let state = { siteConfig: false, responseSiteConfig: true }
      if (data) state.siteConfig = data;
      this.setState(state);
    });
  }

  updateNotification = (notification) => {
    this.setState({ notification: notification });
  }

  render () {

    if (this.state.responseSiteConfig === false || this.state.responseUserStatus === false) return <Loading />

    return (
      <siteConfig.Provider value={ { siteConfig: this.state.siteConfig }}>
        <userContext.Provider value={ { user: this.state.user, refreshUser: this.refreshUser } }>
          <notificationContext.Provider value={ { notification: this.state.notification, updateNotification: this.updateNotification } }>
            <Router>
              <Switch>
                
                <Route exact path="/">
                  <StartPage />
                </Route>

                <Route exact path="/om">
                  <About />
                </Route>

                <Route exact path="/support">
                  <Support />
                </Route>

                <RequireAuth exact path="/checkout/:assetid">
                  <Checkout />
                </RequireAuth>
                
                <RequireAuth exact path="/salj">
                  <Sell />
                </RequireAuth>

                <RequireAuth exact path="/profil">            
                  <Account />
                </RequireAuth>

                <Route>
                  <NotFound />
                </Route>

              </Switch>
            </Router>
            <Notification />
          </notificationContext.Provider>
        </userContext.Provider>
      </siteConfig.Provider>
    )
  }

}

export default App;