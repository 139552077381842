import React from 'react';
import userContext from '../contexts/userContext';
import SteamLoginButton from '../components/SteamLoginButton';
import { Link } from "react-router-dom";

class UserBadge extends React.Component {

	componentDidMount () {

	}
	
	render() {
		
		if (this.context.user === false) return <SteamLoginButton>Logga in</SteamLoginButton>;


		return (
			<Link className="user-badge" to="/profil">
				<img className="avatar" src={ this.context.user.avatarfull } alt="Steam Avatar" />
			</Link>
		)

	}


}

UserBadge.contextType = userContext;

export default UserBadge;