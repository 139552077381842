import React from 'react';
import Modal from '../components/Modal';
import Btn from '../components/Btn';
import { useHistory } from "react-router-dom";

function ItemModal (props) {

	const history = useHistory();

	var assetid = props.showItemModal;
	var item = false;

	if (assetid === false) {
		return null;
	}

	props.items.forEach(item_loop => {
		if (item_loop.asset.assetid === assetid) {
			item = item_loop;
			return true;
		}
	});

	if (item === false) {
		return null;
	}

	var closeModal = () => {
		if (props.closeModal) {
			props.closeModal();
		}
	}

	var gotoCheckout = (assetid) => {
		history.push('/checkout/' + assetid);
	}

	return (
		<Modal className="item-modal" show={ true } closeModal={ closeModal }>
			<div className="layout">
				<div>
					<img className="icon" src={ 'https://steamcommunity-a.akamaihd.net/economy/image/' + item.description.icon_url } alt="Steam item" />
				</div>
				<div>
					<div className="name">{ item.description.name }</div>
					<div className="exterior">{ item.summary.exterior }</div>
					{ item.csgo_float.floatvalue ? (<div className="float">{ item.csgo_float.floatvalue.toFixed(4) }</div>) : null }
					{ item.price ? (<div className="price">{ item.price } Kr</div>) : null }
					<Btn onClick={ () => { gotoCheckout(item.asset.assetid) } }>Köp</Btn>
				</div>
			</div>
		</Modal>
	)
}

export default ItemModal;