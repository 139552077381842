import React from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PageContent from '../components/PageContent';
import Heading from '../components/Heading';

function Support () {
	return (
		<>
			<Navbar />
			<PageContent AutoExpand={ true }>
				<Heading>Support</Heading>
				<p>Löksås ipsum mjuka groda omfångsrik samtidigt sorgliga flera därmed, jäst groda sitt sig ingalunda har är se icke, från har bland inom dunge brunsås i. Hela denna från jäst rännil tiden sin, där ordningens sitt från dimma. Tre kunde redan stora tiden miljoner vidsträckt, miljoner därmed händer äng sitt, mot stig om stora del. Dimmhöljd del träutensilierna sax kan denna för kan sin, hela av vid samma hav äng denna, helt tre hav hans både miljoner varit.</p>
			</PageContent>
			<Footer />
		</>
	)
}

export default Support;