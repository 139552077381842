import React from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PageContent from '../components/PageContent';
import Heading from '../components/Heading';
import api from '../api/api';
import Loader from '../components/Loader';

class Checkout extends React.Component {

	assetid = false;

	state = {
		item: false
	}

	componentDidMount () {
		this.assetid = this.props.match.params.assetid
		api.getItemInfo(this.assetid, (success, data) => {
			this.setState({ item: data });
		});
	}

	renderCheckoutItem = () => {

		return (
			<div className="cart">
				<p>Beställningen gäller följande föremål</p>
				<div className="checkout-item">
					<div className="icon">
						<img src={ 'https://steamcommunity-a.akamaihd.net/economy/image/' + this.state.item.data.description.icon_url } alt="Steam item" />
					</div>
					<div className="details">
						<div className="name">{ this.state.item.data.description.name }</div>
						<div className="exterior">{ this.state.item.data.summary.exterior }</div>
						{ this.state.item.data.csgo_float.floatvalue ? (<div className="float">{ this.state.item.data.csgo_float.floatvalue.toFixed(4) }</div>) : null }
					</div>
					<div className="price">
						{ this.state.item.price ? (<div className="price">{ this.state.item.price } Kr</div>) : null }
					</div>
				</div>
			</div>
		)
	}

	render () {
		return (
			<>
				<Navbar />
				<PageContent className="checkout" AutoExpand={ true }>
					<Heading>Kassa</Heading>
					{ this.state.item === false ? <Loader /> : this.renderCheckoutItem() }
				</PageContent>
				<Footer />
			</>
		)
	}
}

export default Checkout;