import React from "react";
import PageContent from '../components/PageContent';
import Loader from '../components/Loader';

function Loading () {
	return (
		<>
			<PageContent AutoExpand={ true } className="loading">
				<div className="loading-content">
					<Loader />
					<p className="text">Laddar...</p>
				</div>
			</PageContent>
		</>
	)
}

export default Loading;