import React from 'react';
import userContext from '../contexts/userContext';
import NotAuthorized from '../pages/NotAuthorized';
import { Route } from "react-router-dom";

function RequireAuth (props) {

	const context = React.useContext(userContext);

	if (context.user !== false) {
		return (
			<Route { ...props } >
				{ (child_props) => { return React.cloneElement(props.children, child_props) } }
			</Route>
		)
	}

	return (
		<Route { ...props } >
			<NotAuthorized />
		</Route>
	)
}

export default RequireAuth;