import React from 'react';

function Modal (props) {

	var closeModal = () => {
		if (props.closeModal) {
			props.closeModal();
			return;
		}
	}

	return (
		<div className={ 'modal' + ((props.show === true) ? ' open' : '') + (props.className ? ' ' + props.className : '')}>
			<div className="modal-body">
				<div className="header">
					<div className="close" onClick={ closeModal }>X</div>
				</div>
				<div className="content">
					{ props.children }
				</div>
			</div>
		</div>
	)
}

export default Modal;