import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PageContent from '../components/PageContent';
import SteamLoginButton from '../components/SteamLoginButton';

function NotAuthorized () {
	return (
		<>
			<Navbar />
			<PageContent className="not-found" AutoExpand={ true }>
				<div className="title">Denna sida kräver inloggning</div>
				<div className="go-back">
					<SteamLoginButton>Logga in</SteamLoginButton>
				</div>
			</PageContent>
			<Footer />
		</>
	)
}

export default NotAuthorized;