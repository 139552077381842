import React from "react";

function Heading (props) {
	return (
		<div className="heading">
			<div className="wrapper">
				<h1>{ props.children }</h1>
			</div>
		</div>
	)
}

export default Heading;