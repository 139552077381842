import React from "react";

class PageContent extends React.Component {

	state = {
		height: false	
	}

	timeout = false;

	componentDidMount () {
		if (this.props.AutoExpand === true) {
			this.getRequiredHeight();
			window.addEventListener('resize', this.windowResize);
		}
	}

	componentWillUnmount () {
		window.removeEventListener('resize', this.windowResize);
		clearTimeout(this.timeout);
	}

	windowResize = () => {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(this.getRequiredHeight, 200);
	}

	getRequiredHeight = () => {
		this.setState({ height: false });
		var elements = document.querySelectorAll("#root > *");
		var target_element = false;
		var window_height = window.innerHeight;
		var elements_total_height = 0;

		elements.forEach(element => {
			elements_total_height += element.offsetHeight;
			if (element.id === 'auto-expand-target') {
				target_element = element;
			}
		});

		if (target_element === false) {
			return false;
		}

		if (window_height > elements_total_height) {
			let target_element_height = target_element.offsetHeight;
			let new_height = target_element_height + (window_height - elements_total_height);
			this.setState({ height: new_height });
		} else if (this.state.height !== false) {
			this.setState({ height: false });
		}


	}

	render () {
		return (
			<div className={ 'page-content' + (this.props.className ? ' ' + this.props.className : '') } id={ (this.props.AutoExpand === true ? 'auto-expand-target' : '' ) } style={ this.state.height !== false ? { minHeight: this.state.height + 'px' } : {} }>
				{ this.props.children }
			</div>
		)
	}

}

export default PageContent;