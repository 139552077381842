import React from 'react';
import Btn from './Btn';

class SelectedItems extends React.Component {

	componentDidMount () {

	}

	renderItems = () => {
		let output = [];

		Object.keys(this.props.selectedItems).forEach(assetid => {
			let select = this.props.selectedItems[assetid];
			let item = this.props.inventory[assetid];
			output.push(
				<div className="selected-item" key={ assetid } >

					<div className="item-icon">
						<img className="icon" src={ 'https://steamcommunity-a.akamaihd.net/economy/image/' + item.description.icon_url } alt="Steam item" />
					</div>

					<div className="item-info">
						<div className="name">{ item.description.name }</div>
						<div className="exterior"></div>
					</div>

					<div className="price">
						<input type="number" placeholder="pris" min="0" onChange={ (event) => { this.props.priceChange(event, assetid) } } value={ select.price } required/>
					</div>

					<div className="remove" onClick={ () => { this.props.unselectItem(assetid) } }>
						x
					</div>

				</div>
			)
		});

		return output;
	}

	render () {

		if (!Object.keys(this.props.selectedItems).length) {
			return (
				<div className="selected-items">
					<div className="items">
						<h2>Välj skins</h2>
						<p>Markera vilka skins du vill lägga ut för försäljning genom att klicka på dom.</p>
					</div>
				</div>
			)
		}

		return (
			<div className="selected-items">
				<div className="items">
					<h2>Valda föremål</h2>
					<form onSubmit={ (e) => { e.preventDefault() } }>
						{ this.renderItems() }
						<Btn onClick={ this.props.postListItems }>Lägg ut till försäljning</Btn>
					</form>
				</div>
			</div>
		)

	}
}

export default SelectedItems;