import React from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PageContent from '../components/PageContent';
import Hero from '../components/Hero';
import ListedItems from '../components/ListedItems';

function StartPage () {
	return (
		<>
			<Navbar />
			<Hero />
			<PageContent AutoExpand={ true }>
				<ListedItems />
			</PageContent>
			<Footer />
		</>
	)
}

export default StartPage;