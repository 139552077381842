import React from "react";
import { NavLink, Link } from "react-router-dom";
import PageContent from '../components/PageContent';
import UserBadge from '../components/UserBadge';
import userContext from '../contexts/userContext';

class Navbar extends React.Component {

	menuItems = [
		{
			'title': 'Shop',
			'url': '/',
			'auth': false
		},
		{
			'title': 'Support',
			'url': '/support',
			'auth': false
		},
		{
			'title': 'Om oss',
			'url': '/om',
			'auth': false
		},
		{
			'title': 'Sälj',
			'url': '/salj',
			'auth': true
		}
	];

	renderMenuItems = () => {
		let output = [];

		this.menuItems.forEach((item, key) => {

			if (item.auth && item.auth === true) {
				if (this.context.user === false) return false;
			}

			output.push(
				<div className="link" key={ key }>
					<NavLink exact activeClassName="active" to={ item.url }>{ item.title }</NavLink>
				</div>
			)
		});

		return output;
	}

	renderUserBadge = () => {
		return (
			<div className="link login">
				<UserBadge />
			</div>
		)
	}

	render () {
		return (
			<div className="navbar">
				<PageContent className="navbar-wrapper">
					<div className="logo">
						<Link to="/">
							<img src="/images/skinbutiken-logo.png" alt="Logo" />
						</Link>
					</div>
					<div className="menu">
						{ this.renderMenuItems() }
						{ this.renderUserBadge() }
					</div>
				</PageContent>
			</div>
		)
	}
	
}

Navbar.contextType = userContext;

export default Navbar;