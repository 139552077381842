import React from 'react';
import { Link } from "react-router-dom";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PageContent from '../components/PageContent';

function NotFound () {
	return (
		<>
			<Navbar />
			<PageContent className="not-found" AutoExpand={ true }>
				<div className="title">404</div>
				<div className="go-back">
					<Link to="/">Gå till startsidan</Link>
				</div>
			</PageContent>
			<Footer />
		</>
	)
}

export default NotFound;