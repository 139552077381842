import React, { useEffect } from 'react';
import PageContent from '../components/PageContent';
import notificationContext from '../contexts/notificationContext'

function Notification () {

	var timer;

	useEffect(() => {
		return () => {
			clearTimeout(timer);
		}
	})

	const context = React.useContext(notificationContext);

	if (context.notification === false) return null;

	timer = setTimeout(() => {
		context.updateNotification(false);
	}, 4000)

	return (
		<div className="notification">
			<PageContent>
				<div className="content">
					{ context.notification.content }
				</div>
			</PageContent>
		</div>
	)


}

export default Notification;