import React from "react";
import PageContent from '../components/PageContent';

function Footer () {
	return (
		<div className="footer">
			<PageContent className="wrapper">
				@ Skinbutiken AB 2021
			</PageContent>
		</div>
	)
}

export default Footer;