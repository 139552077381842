import React from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PageContent from '../components/PageContent';
import Heading from '../components/Heading';
import userContext from '../contexts/userContext';

function Account () {

	const context = React.useContext(userContext);
	const userData = { ...context.user }

	return (
		<>
			<Navbar />
			<PageContent className="account" AutoExpand={ true }>
				<Heading>Mitt konto</Heading>
				<p>Löksås ipsum mjuka groda omfångsrik samtidigt sorgliga flera därmed, jäst groda sitt sig ingalunda har är se icke, från har bland inom dunge brunsås i. Hela denna från jäst rännil tiden sin, där ordningens sitt från dimma. Tre kunde redan stora tiden miljoner vidsträckt, miljoner därmed händer äng sitt, mot stig om stora del. Dimmhöljd del träutensilierna sax kan denna för kan sin, hela av vid samma hav äng denna, helt tre hav hans både miljoner varit.</p>
				<div className="layout">
					<div className="account-info">

						<div className="row">
							<div className="label">Steam</div>
							<div className="value">{ userData.personaname }</div>
							<div className="edit"></div>
						</div>

						<div className="row">
							<div className="label">Email</div>
							<div className="value">{ userData.email }</div>
							<div className="edit">Edit</div>
						</div>

						<div className="row">
							<div className="label">Trade URL</div>
							<div className="value">{ userData.trade_url }</div>
							<div className="edit">Edit</div>
						</div>

						<div className="row">
							<div className="label">API key</div>
							<div className="value">{ userData.has_api_key === true ? '*******' : '' }</div>
							<div className="edit">Edit</div>
						</div>

					</div>

					<div className="invoice-details">

					</div>
				</div>
			</PageContent>
			<Footer />
		</>
	)
}

export default Account;